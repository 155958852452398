<template>
	
		<div v-if="openNew" class="modal addNew">
			<h2 class="text-xl font-bold">Add new row</h2>
			<p class="mt-2">Please complete all fields below to add a new row.</p>
			
			<form @submit.prevent="newRow" ref="addNewForm" :class="['mt-10']">
				
				
				<div class="field field--full">
					<label>Account name</label>
					<input name="Account" type="text">
				</div>
				
				<div class="category mt-8">
					<h3>Current</h3>
					<div v-for="header in current.headers" :key="header.id" class="field">
						<label key="label1">{{header}}</label>
						<select :name="header" v-if="metaData[header] && metaData[header].values.length > 0" ref="select" class="custom-select custom-select-sm">
							<option></option>
							<option v-for="option in metaData[header].values" :key="option.id" :value="option">{{option}}</option>
						</select>
						<input :name="header" v-else type="text">
					</div>
				</div>

				<div class="category">
					<h3>Future</h3>
					<div v-for="header in future.headers" :key="header.id" class="field">
						<label key="label1">{{header}}</label>
						<select :name="header" v-if="metaData[header] && metaData[header].values.length > 0" ref="select" class="custom-select custom-select-sm">
							<option></option>
							<option v-for="option in metaData[header].values" :key="option.id" :value="option">{{option}}</option>
						</select>
						<input :name="header" v-else type="text">
					</div>
				</div>
				
				<div class="category">
					<h3>General</h3>
					<div v-for="header in general.headers" :key="header.id" class="field">
						<label key="label1">{{header}}</label>
						<select :name="header" v-if="metaData[header] && metaData[header].values.length > 0" ref="select" class="custom-select custom-select-sm">
							<option></option>
							<option v-for="option in metaData[header].values" :key="option.id" :value="option">{{option}}</option>
						</select>
						<input :name="header" v-else type="text">
					</div>
				</div>
				
				<div class="flex">
					<input key="submit1" type="submit" class="button mr-5" value="Submit">
					<div class="button button--secondary" @click="cancelNew()">Cancel</div>
				</div>
			</form>
		</div>


</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'AddRow',
	components: {
		
	},
	computed: {
		...mapState(['metaData', 'current', 'future', 'general', 'lastRowNum', 'originalSheet'])
	},
	props: [
		"openNew"
	],
	data() {
		return {
			
		}
	},
	methods: {
		async newRow(event) {
			
			let newEntry = new Object();
			Array.from(new FormData(event.target)).forEach(field => {
				if(field[1] != "") {
					newEntry[field[0]] = field[1];
				}
			})

			newEntry["Current Tiering"] = "='Score board calculations'!H" + (this.lastRowNum+1);
			newEntry["current band"] = `=IF(PERCENTRANK.INC($H$5:$H$104,H${this.lastRowNum+1})<0.9,3,IF(AND(0.9<PERCENTRANK.INC($H$5:$H$104,H${this.lastRowNum+1}),PERCENTRANK.INC($H$5:$H$104,H${this.lastRowNum+1})<0.95),2,1))`;
			newEntry["Projected Tiering"] = "='Score board calculations'!O" + (this.lastRowNum+1);
			newEntry["future band"] = `=IF(PERCENTRANK.INC($U$5:$U$104,U${this.lastRowNum+1})<0.9,3,IF(AND(0.9<PERCENTRANK.INC($U$5:$U$104,U${this.lastRowNum+1}),PERCENTRANK.INC($U$5:$U$104,U${this.lastRowNum+1})<0.95),2,1))`;

			await this.originalSheet.addRow(newEntry);
			this.$store.dispatch('loadSheet');
			this.$emit("closeNew");
		},
		cancelNew() {
			this.$emit("closeNew");
		}
	},
	mounted() {
		
	}
}


</script>


<style lang="scss" scoped>

	.modal {
		height: 80vh;
		overflow: scroll;
		position: fixed;
		z-index: 99;
		left: 50%;
		top: 50px;
		transform: translateX(-50%);
	}
	form {
		input[type=email],
		input[type=text],
		input[type=password] {
			max-width: none;
		}
	}

	.category {
		border-bottom: 1px solid #ccc;
		padding-bottom: 3rem;
		margin-bottom: 3rem;
		display: flex;
		flex-wrap: wrap;
		gap: 2rem;
	}
	.addNew {
		.field {
			width: calc(50% - 1rem);
			margin-bottom: 0;

			&--full {
				width: 100%;
			}
		}

		h3 {
			display: block;
			width: 100%;
		}
	}
	
	.loginForm {
		
		margin: 50px auto 0;
	}


</style>
