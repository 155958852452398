<template>
	
	<div class="container-fluid">
		<!-- <div class="flex flex-col items-center pt-20">
			<img src="@/assets/calor-logo.png" class="calorLogo" />
			<h1 class="font-bold text-lg mt-7">Account classification</h1>
			<p class="text-xs">Powered by freestyle.agency</p>
		</div>

		<transition name="slide" appear>
		<div key="slideAni" v-if="animate">
		<div key="loginform" class="modal loginForm mt-30">
			<h2 class="text-xl font-bold">Sign in</h2>
			<p class="mt-2">Please sign in to access your account classification tool.</p>
			<form @submit.prevent="login" ref="loginForm" :class="['mt-10', loggedIn ? 'loggedIn' : '']">
				<div key="field1" class="field">
					<label key="label1">Username</label>
					<input key="input1" type="text" v-model="username" placeholder="Enter username">
				</div>
				<div key="field2" class="field">
					<label key="label2" for="">Password</label>
					<input key="input2" type="password" v-model="password" placeholder="Enter your password">
				</div>
				<div v-if="isError">{{isError}}</div>
				<input key="submit1" type="submit" class="button" value="Sign in">
			</form>
		</div>
	</div>
	</transition>
-->
</div>
</template>

<script>
import Header from '@/components/Header';
import { mapState } from 'vuex';
import axios from 'axios';

export default {
	name: 'Login',
	components: {
		
	},
	computed: {
	},
	data() {
		return {
			isError: null,
			username: "",
			password: "",
			loggedIn: false,
			animate: false
		}
	},
	methods: {
		login() {
			this.loggedIn = true;

			const user = {
				username: this.username,
				password: this.password
			}
			axios({
				url: "https://freestyle-prioritisation-app.azurewebsites.net/api/auth/get/token",
				data: user,
				method: "POST",
			})
			.then((resp) => {
				this.loggedIn = false;

				if(!resp.data.isAuthenticated) {
					this.isError = resp.data.message;
				} else if(resp.data.token) {
					this.$cookies.set('auth', resp.data.token);
					this.$router.push({
						name: "Current"
					});
				}
			})
			.catch((err) => {
				console.log('error');
				this.loggedIn = false;
				this.isError = "There has been an error logging in, try again"
			});
		}
	},
	mounted() {
		this.$router.push({
				name: "Current"
			});

		this.animate = true;

		if(this.$cookies.isKey('auth')) {
			console.log('logged in before, redirect')
			this.loggedIn = true;
			this.$router.push({
				name: "Current"
			});
		} else {
			console.log('no logged in before')

		}
	}
}


</script>


<style lang="scss" scoped>
	form {
		input[type=email],
		input[type=text],
		input[type=password] {
			max-width: none;
		}

		&.loggedIn {
			pointer-events: none;
			opacity: 0.6;
		}
	}
	.calorLogo {
		max-width: 174px;
	}
	.loginForm {
		margin: 50px auto 0;
	}

	.slide-enter-active {
		transition: all 1s cubic-bezier(.11,.73,.55,1.04);
		opacity: 0;
		transform: translateY(-20px);
	}
	.slide-enter-to {
		opacity: 1;
		transform:translateY(0px);
	}

</style>
