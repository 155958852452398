import { createWebHistory, createRouter } from "vue-router";
import Login from "@/views/Login.vue";
import Current from "@/views/Current.vue";
import General from "@/views/General.vue";
import Future from "@/views/Future.vue";

const routes = [
  {
    path: "/current",
    name: "Current",
    component: Current,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/general",
    name: "General",
    component: General,
  },
  {
    path: "/future",
    name: "Future",
    component: Future,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;